import React, { useState } from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import '../components/NavBar.css';
import discord from "../assets/images/discord.png"
import twitter from "../assets/images/twitter.png"
import opensea from "../assets/images/opensea.png"
import linktr from "../assets/images/linktr.png"
import logo from "../assets/images/hs-logo.png"
import sweetest from "../assets/images/sweetest.png"
import wave from "../assets/images/wave.png"
import Butter from "../assets/images/1.png"
import Chips from "../assets/images/2.png"
import Chocolate from "../assets/images/3.png"
import Rainbow from "../assets/images/4.png"
import Strawberry from "../assets/images/5.png"
import Sunshine from "../assets/images/6.png"
import Vanilla from "../assets/images/7.png"
import Holder_Benefits from "../assets/images/Holder Benefits.jpg"
import styled, { keyframes } from "styled-components";
import Community from "../assets/images/Community and value growth.jpg";
import Traits from "../assets/images/Traits and rarities.jpg";
import More_than from "../assets/images/More than just an art.jpg";
import Roadmap from './Roadmap';
import Team from './Team';
import Faq from './Faq';
import Kingdom from './kingdom';

const move = keyframes`
0% { transform: translateY(-5px)         }
    50% { transform: translateY(10px) translateX(10px)        }
    100% { transform: translateY(-5px)         }
`;

const AboutSection = styled.section`
  width: 100vw;
  position: relative;
  background-color: #5aa2d8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Waves = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  top: -8rem;
  @media only Screen and (max-width: 40em) {
    top: -2rem;
  }
`;

const Main = styled.div`
  margin: 0 15rem;
  margin-top: 15rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media only Screen and (max-width: 64em) {
    margin: 0 calc(5rem + 5vw);
    margin-top: 10rem;
  }
  @media only Screen and (max-width: 40em) {
    align-items: center;
    margin: 3rem calc(3rem + 3vw);
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  display: inline-block;
`;

const CurvedLine = styled.div`
  width: 7rem;
  height: 2rem;
  border: solid 5px var(--purple);
  border-color: var(--purple) transparent transparent transparent;
  border-radius: 150%/60px 70px 0 0;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only Screen and (max-width: 40em) {
    flex-direction: column;
  }
`;

const Imgmove = styled.img`
  width: 400px;
  height: 400px;
  @media only Screen and (max-width: 40em) {
    width: 350px;
    height: 350px;
  }
`;

const Rocket = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 40%;
  padding-bottom: 5rem;
  animation: ${move} 2.5s ease infinite;
  @media only Screen and (max-width: 40em) {
    width: 50vw;
    padding-bottom: 0;
  }
`;

const Text = styled.h4`
  font-size: calc(0.5rem + 1vw);
  line-height: 1.5;
  color: var(--nav2);
  @media only Screen and (max-width: 40em) {
    font-size: calc(0.5rem + 3vw);
  }
`;
const Circle = styled.span`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: black;
  margin-right: 0.5rem;
  margin-top: 1rem;
`;
const AboutText = styled.div`
  width: 50%;
  position: relative;
  @media only Screen and (max-width: 40em) {
    width: 100%;
  }
`;

const home = () => {
	const [open, setOpen] = useState(false);

	const handleClick = () => {
		setOpen(!open);
	};

	const closeMenu = () => {
		setOpen(false);
	};
        return(
            <>

              <nav className="navbar navbar-expand-lg bg-none fixed-top">
              <a href="/" class="nav-logo">
                <img class="logo" src={logo}  width="300" height="50" draggable="false"/>
              </a>
              <span class="d-md-block d-block mx-auto">
                                          <a href="https://linktr.ee/rbmc">
                                              <img class="social-icon" src={linktr} width="41" height="41" />
                                          </a>
                                          <a href="https://discord.com/invite/dzBSk8ZhFz">
                                              <img class="social-icon" src={discord} width="41" height="41" />
                                          </a>
                                          <a href="https://twitter.com/rbmc_nft">
                                              <img class="social-icon mx-2" src={twitter} width="41" height="41" />
                                          </a>
                                          <a href="https://opensea.io/collection/rbmc">
                                              <img class="social-icon" src={opensea} width="41" height="41"/>
                                          </a>
                                          
                    </span>                                      
                    <div onClick={handleClick} className="nav-icon">
                      {open ? <FiX /> : <FiMenu />}
                    </div>
                    <ul className={open ? 'nav-links active' : 'nav-links'}>
                      <li className="nav-item">
                      <a href="#About">
                        <span class="nav-link">About us</span>
                      </a>
                      </li>
                      <li className="nav-item">
                      <a href="#Utility">
                                <span class="nav-link">Utility</span>
                            </a>
                      </li>
                      <li className="nav-item">
                      <a href="#roadmap">
                                <span class="nav-link">Roadmap</span>
                            </a>
                      </li>
                      <li className="nav-item">
                      <a href="#team">
                                <span class="nav-link">Team</span>
                            </a>
                      </li>
                      <li className="nav-item">
                      <a href="#faq">
                                <span class="nav-link">FAQs</span>
                            </a>
                      </li>
                      <a href="/mint">
                                <span class="ms-2 btn btn-light btn-mint">Mint</span>
                    </a>
                    </ul>
                    
                  </nav>


                {/* <!-- Hero --> */}
                <section>
                    <div style= {{ height: "100vh"}} class="bg-hero position-relative">
                        <div style= {{ height: "100%"}} class="d-flex align-items-center justify-content-center">
                            <img data-aos="zoom-in" data-aos-duration="750" src={sweetest} width="95%" style={{ maxWidth: 700 }} draggable="false"/>
                        </div>
                        <img class="position-absolute bottom-0" src={wave} width="100%" draggable="false"/>
                    </div>
                </section>

                {/* <!-- About --> */}
                <section id="About">
                    <div data-aos="zoom-in-up" data-aos-duration="750" data-aos-mirror="true" class="container-xl pt-5 text-center">
                    <h1 class="fw-semibold col-8 col-md-12 mx-auto display-5 mt-5 mb-4">Welcome to the Royal Bully Millionaires Club</h1>
                    <Text>
                    RBMC is an exclusive top-performer community, business incubator, and NFT project 
                    based on the Ethereum ERC-721 blockchain network. Members holding one of our 
                    10,000 premium digital art pieces will receive access to our exclusive community filled 
                    with success-driven highly motivated people where members can broaden their network 
                    and expand their opportunities while providing exclusive premium rewards to our 
                    holders. With a fully transparent and doxxed team, established partners and advisors, 
                    and a powerful network of community members, we are excited to welcome you to the 
                    Royal Bully Millionaires Club.
                    </Text>
        <div>
        <h1 class="fw-semibold col-8 col-md-12 mx-auto display-5 mt-5 mb-4">Community and Value Growth</h1>
          <CurvedLine />
        </div>
        <Content>
          <Rocket>
            <Imgmove src={Community} alt="" />
          </Rocket>
          <AboutText>
              <br/>
            <Text>
            There are 4 price tiers in a form of phases. This will enable the early minters to be 
            rewarded the most for their trust and support in the Royal Team while the community 
            and its benefits grow. The early minters will get the best price RBMC NFT can ever be.
            </Text>
          </AboutText>
        </Content>

        <div>
        <h1 class="fw-semibold col-8 col-md-12 mx-auto display-5 mt-5 mb-4">Traits & Rarities</h1>
          <CurvedLine />
        </div>
        <Content>
          <AboutText>
              <br/>
            <Text>
            Each Royal Bully in our collection is randomly computer-generated and unique from 
            over 61 traits, including bully color, emotion, hat, clothes, background, and accessory 
            with over 1.1 million possible combinations. All bullies are royal, although <b style={{fontSize: 25}}>100 rare royal 
            traits were inherited by some.</b> Legend says that these rare traits behold special benefits 
            that only their holders will receive and experience when the right time comes.
            </Text>
          </AboutText>
          <Rocket>
            <Imgmove src={Traits} alt=""  />
          </Rocket>
        </Content>

<br/><br/>
        <div id="Utility">
        <h1 class="fw-semibold col-8 col-md-12 mx-auto display-5 mt-5 mb-4">More than just an art</h1>
          <CurvedLine />
        </div>
        <Content>
          <Rocket>
            <Imgmove src={More_than} alt=""  />
          </Rocket>
          <AboutText>
              <br/>
            <Text>
            Holding our RBMC NFT does not only give you a premium art, it also provides you 
            <b style={{fontSize: 25}}> membership access to The Kingdom</b> that not only continuously grows its network and 
            opportunities but also brings forth flourishing benefits as well. All Royal Bully can be 
            used as your digital identity, and expand your digital and IRL network and opportunities.
            </Text>
          </AboutText>
        </Content>
                    </div>
                    <Kingdom/>
                    <div class="d-flex w-100 mt-5 pt-5 justify-content-center">
                    <div class="marquee">
                        <ul class="marquee-content">
                            <li><img src={Butter}/></li>
                            <li><img class="mx-auto" src={Chips}/></li>
                            <li><img class="mx-auto" src={Chocolate}/></li>
                            <li><img class="mx-auto" src={Rainbow}/></li>
                            <li><img class="mx-auto" src={Strawberry}/></li>
                            <li><img class="mx-auto" src={Sunshine}/></li>
                            <li><img class="mx-auto" src={Vanilla}/></li>
                            <li><img class="mx-auto" src={Butter}/></li>
                            <li><img class="mx-auto" src={Chips}/></li>
                            <li><img class="mx-auto" src={Chocolate}/></li>
                            <li><img class="mx-auto" src={Rainbow}/></li>
                            <li><img class="mx-auto" src={Strawberry}/></li>
                            <li><img class="mx-auto" src={Sunshine}/></li>
                            <li><img class="mx-auto" src={Vanilla}/></li>
                        </ul>
                        
                    </div>
                    </div>
                    
                </section>

                {/* <!-- Benefits --> */}
                <section>
                    <div style={{ height: "100%", minHeight: "50vh"}} class="d-flex align-items-center py-5 bg-benefits position-relative">
                        <div class="container-xl ps-3 pe-2 px-md-0 pb-3 pb-md-5 mb-0 mb-md-5">
                            <div data-aos="zoom-in-up" data-aos-duration="750" data-aos-mirror="true" class="benefits-card">
                                <div class="row g-0">
                                <div class="col-md-6 col-12 d-flex align-items-center border-thick-right">
                                    <div class="mx-auto col-9 col-md-8 my-0 my-md-5 py-5 py-md-5">
                                        <h1 class="fw-semibold display-5 mb-4">Holder Benefits</h1>
                                        <p class="fs-4 mb-0 lh-sm">
                                        •Exclusive access to The Kingdom <br/><br/>
                                        •Royal Profit Airdrop per NFT (Stackable) <br/><br/>
                                        •RBMC Holder business exposure and promotion to thousands of people <br/><br/>
                                        •Build a network with like-minded and influential people <br/><br/>
                                        •Exclusive entry for RBMC Holder only giveaways <br/><br/>
                                        •Exclusive invitation to royal events and workshops (With special guests) <br/><br/>
                                        •Exclusive access to our digital land (Metaverse) <br/><br/>
                                        •Exclusive discounts and privileges on real-world businesses (Shopping, Hotel, 
                                        Restaurants, Services, Transport, and more)
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 col-12">
                                    <img src={Holder_Benefits} style={{ minHeight: "300px"}} class="card-img h-100 rounded-end"/>
                                </div>
                                </div>
                            </div>
                        </div>
                        <img class="position-absolute bottom-0" src={wave} width="100%" draggable="false"/>
                    </div>
                </section>

                {/* <!-- Benefits --> */}
                {/* <section>
                    <div style={{ height: "100%"}} class="d-flex align-items-center pt-0 pt-md-5 mb-0 position-relative">
                        <div class="container-xl pt-0 pt-md-5 text-center">
                            {/* <img data-aos="zoom-in-up" data-aos-duration="750" data-aos-mirror="true" class="col-8 col-md-4 mx-auto mt-5 mb-3 mb-md-5" src={roadmap} width="100%" draggable="false"/>
                            <p class="fs-4 col-10 col-md-6 mx-auto lh-sm"></p> */}{/* 
                            <div data-aos="zoom-in-up" data-aos-duration="750" data-aos-mirror="true" class="d-none d-md-block">
                                <img class="mb-0" src={roadmap1} width="100%" draggable="false"/>
                            </div>
                            <div data-aos="zoom-in-up" data-aos-duration="750" data-aos-mirror="true" class="d-block d-md-none">
                                <img class="mb-0" src={mobileRoadmap} width="100%" draggable="false"/>
                                <br/><br/>
                            </div>
                        </div>
                    </div>
                </section> */}

                

                <Roadmap />
                {/* <Team/> */}
                <Faq />
                <footer>
                    <div class="bg-dark text-white text-center">
                        <p class="p-2 mb-0">&copy; Copyright 2022, RBMC. All rights reserved.</p>
                    </div>
                </footer>

                
            </>
        )
    
}

export default home