import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Home from './pages/home';
import Mint from './pages/mint';
import Kingdom from './pages/kingdom';
import AOS from 'aos';



function App(){
  AOS.init();
    return(
      <Router >
      < >
  
          <Switch >
          <Route exact path = "/" >
            <Home/> 
          </Route> 
        <Route exact path = "/mint" >
          <Mint/>
          </Route>
          <Route exact path = "/kingdom" >
          <Kingdom/>
          </Route>
          </Switch> 
          </>
        </Router> 
    );
}

export default App;