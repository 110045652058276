import React, {
    lazy,
    Suspense
} from 'react'
import styled from 'styled-components';

import img1 from '../assets/Team/Doxxed Team 1.jpg';
import img2 from '../assets/Team/Doxxed Team 2.jpg';
import img3 from '../assets/Team/Doxxed Team 3.jpg';
import img4 from '../assets/Team/Doxxed Team 4.jpg';
import img5 from '../assets/Team/Doxxed Team 5.jpg';
import img6 from '../assets/Team/Doxxed Team 6.jpg';
import img7 from '../assets/Team/Doxxed Team 7.jpg';
import img8 from '../assets/Team/Doxxed Team 8.jpg';
import img9 from '../assets/Team/Doxxed Team 9.jpg';
import Loading from '../components/Loading';
// import ConfettiComponent from '../Confetti';

const ConfettiComponent = lazy(() =>
    import ("../components/Confetti"));


const Section = styled.section `
min-height: 100vh;
width: 100vw;
background-color: ${props => props.theme.body};
position: relative;
overflow: hidden;
`
const Title = styled.h1 `
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.text};
  width: fit-content;

  @media (max-width: 40em){
    font-size: ${(props) => props.theme.fontxl};

}
`;

const Container = styled.div `
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-between;
align-items: center;
flex-wrap: wrap;

@media (max-width: 64em){
width: 80%;
}
@media (max-width: 48em){
width: 90%;
justify-content: center;
}
`
const Container1 = styled.div `
width: 75%;
margin: 2rem auto;
display: flex;
align-items: center;
flex-wrap: wrap;
justify-content: center;

@media (max-width: 64em){
width: 80%;
}
@media (max-width: 48em){
width: 90%;
justify-content: center;
}
`

const Item = styled.div `
width: calc(22rem - 4vw);
padding: 1rem 0;
color: ${props => props.theme.body};
margin: 2rem 1rem;
position: relative;
z-index:5;

backdrop-filter: blur(4px);

border: 2px solid ${props => props.theme.text};
border-radius: 20px;

&:hover{
  img{
    transform: translateY(-2rem) scale(1.5);
  }
}

@media (max-width: 30em){
width: 70vw;
}

`

const ImageContainer = styled.div `
width: 100%;
margin: 0 auto;
background-color:${props => props.theme.carouselColor};
border: 1px solid ${props => props.theme.text};
padding: 1rem;

border-radius: 20px;
cursor: pointer;

img{
  width: 100%;
  height: auto;
transition: all 0.3s ease;

}
`

const Name = styled.h2 `
font-size: 30px;
display: flex;
align-items: center;
text-align: center;
justify-content: center;
text-transform: uppercase;
color: ${props => props.theme.text};
margin-top: 1rem;
`

const Position = styled.h2 `
font-size: 25px;
display: flex;
text-align: center;
align-items: center;
justify-content: center;
text-transform: capitalize;
color: ${props => `rgba(${props.theme.textRgba},0.9)`};
font-weight:400;
`

const MemberComponent = ({
    img,
    name = " ",
    position = " "
}) => {

    return ( <Item >
        <ImageContainer >
        <img width = {
            1000
        }
        height = {
            1000
        }
        src = {
            img
        }
        alt = {
            name
        }
        /> 
        </ImageContainer> 
        <Name > {
            name
        } </Name> 
        <Position > {
            position
        } </Position> 
        </Item>
    )
}


const Team = () => {
    return ( 
    <Section  id = "team" >
        <Suspense fallback = { < Loading />
        } >
        <ConfettiComponent /> 
        </Suspense> 
        <div data-aos="zoom-in-up" data-aos-duration="750" data-aos-mirror="true" class="container-xl pt-5 text-center">
         <h1 class="fw-semibold col-8 col-md-12 mx-auto display-5 mt-5 mb-4">The Fully Doxxed Royal Team</h1>
        </div>
        <Container >
        <MemberComponent img = {
            img1
        }
        name = "DANIEL APOLINARIO"
        position = "founder" />
        <MemberComponent img = {
            img2
        }
        name = "JILIANNE UY"
        position = "Founder" />
        </Container>
        <Container1 >
        <MemberComponent img = {
            img3
        }
        name = "KENJIE CUETO"
        position = "Moderator" />
        </Container1>
        <Container >
        <MemberComponent img = {
            img4
        }
        name = "CATTLEYA LINGON"
        position = "HR/SUPPORT" />
        <MemberComponent img = {
            img5
        }
        name = "JASMIN MARTINEZ"
        position = "HR/SUPPORT" />
        <MemberComponent img = {
            img6
        }
        name = "TIFFANY DISCAYA"
        position = "HR/SUPPORT" />
        <MemberComponent img = {
            img7
        }
        name = "AVERY MERCADO"
        position = "HR/SUPPORT" />
        <MemberComponent img = {
            img8
        }
        name = "ELRISH PRUNA"
        position = "HR/SUPPORT" />
        <MemberComponent img = {
            img9
        }
        name = "RICHARD MADRIDANO"
        position = "HR/SUPPORT" />


        </Container> </Section>
    )
}

export default Team