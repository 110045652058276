import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import React, {
    useLayoutEffect,
    useRef
} from 'react'
import styled from 'styled-components';
import Accordion from '../components/Accordion';


const Section = styled.section `
min-height: 100vh;
height: auto;
width: 100vw;
background-color: ${props => props.theme.text};
position: relative;
color: ${(props) => props.theme.body};
overflow: hidden;


display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`
const Title = styled.h1 `
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: uppercase;
  color: ${(props) => props.theme.body};
  
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.carouselColor};
  width: fit-content;

  @media (max-width: 48em){
  font-size: ${(props) => props.theme.fontxl};

  }
`;

const Container = styled.div `
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-between;
align-content: center;

@media (max-width: 64em){
  width: 80%;
  }
  @media (max-width: 48em){
  width: 90%;
  flex-direction: column;

  &>*:last-child{
    &>*:first-child{

    margin-top: 0;
}

  }
  }
`
const Box = styled.div `
width: 45%;
@media (max-width: 64em){
  width: 90%;
  align-self: center;
  }

`

const Faq = () => {

    const ref = useRef(null);
    gsap.registerPlugin(ScrollTrigger);
    useLayoutEffect(() => {

        let element = ref.current;

        ScrollTrigger.create({
            trigger: element,
            start: 'bottom bottom',
            end: 'bottom top',
            pin: true,
            pinSpacing: false,
            scrub: 1,
            // markers:true,
        })

        return () => {
            ScrollTrigger.kill();
        };
    }, [])

    return ( <Section ref = {
            ref
        }
        id = "faq" >
        <div data-aos="zoom-in-up" data-aos-duration="750" data-aos-mirror="true" class="container-xl pt-5 text-center">
         <h1 class="fw-semibold col-8 col-md-12 mx-auto display-5 mt-5 mb-4">FAQ</h1>
        </div>

        <Container >

        <Box >
        <Accordion ScrollTrigger = {
            ScrollTrigger
        }
        title = "What are RBMC’s mission and vision?" >
        <strong>Mission:</strong><br/>
        •To unite success-driven highly motivated people and become one big entity.<br/>
        •To build an exclusive community where RBMC holders have real-world benefits.<br/>
        •To build a community where people can broaden their network and expand their opportunities<br/>
        <strong>Vision:</strong><br/>
        •To become an exclusive community that will continuously grow, provide exclusive rewards to our holders, and bring forth continuous flourishing dividends.
        </Accordion> 
        <Accordion ScrollTrigger = {
            ScrollTrigger
        }
        title = "How the idea was built?" >
        •Daniel and Jilianne (Founders of RBMC) started aiding in mobilizing IRL construction projects for private companies since 2014. Having the same passion to expand their knowledge and network, they started diversifying their portfolio from aiding construction projects to fulfilling purchase orders and supplying general merchandise, medical equipment, medications, food, and more. These deals and transactions enabled them to meet influential people, further developing their network and growing their opportunities.<br/><br/>
        They both also have been trading cryptocurrency since 2017 (started when BTC was $2k and ETH was $200), investing in ICOs, studying the crypto economy, and now NFTs. Back then, it was hard for them to find the right people to build their network, which made them focus more on fulfilling contracts and growing their knowledge. They met the right, trusted, and influential people along the way but they did not just only invest so much money for their network development but precious time as well. Looking back at the challenges they faced, they were inspired to build a way to make it easier for people to build a network while lowering the risk of spending a substantial unnecessary amount of time and money. The founders saw a good opportunity to create an exclusive community in a form of NFT that will gather members with the same goal of building and expanding their network while giving continuous benefits as the company continuously grows, bringing Royal Bully Millionaires Club to the world.     
        </Accordion> 
        {/* <Accordion ScrollTrigger = {
            ScrollTrigger
        }
        title = "Does RBMC have proof of the legal contracts they helped fulfilling with?" >
        <strong>Some Of The Hundreds Of Previously Funded Contracts</strong><br/>
        *Please note that due to the Non-Disclosure Agreement, we are required to cover personal/confidential information and were only allowed to show selected contracts.<a href="https://drive.google.com/drive/folders/1IZXsEU7PbK1tf-TRasIZDw_UmCUSQn0H?usp=sharing">https://drive.google.com/drive/folders/1IZXsEU7PbK1tf-TRasIZDw_UmCUSQn0H?usp=sharing</a> 
        </Accordion>  */}
        <Accordion ScrollTrigger = {
            ScrollTrigger
        }
        title = "How to become a Royal Bully?" >
        <strong>FOR DESKTOP:</strong><br/>
        A. <strong>Download and create a MetaMask account.</strong> <br/>
        (NEVER SHARE your seed phrase to anyone and keep it safe for future recovery if needed)<br/>
        •<strong>Tutorial - </strong><a href="https://youtu.be/WAStJtjYI_c">https://youtu.be/WAStJtjYI_c</a> <br/>
        •<strong>MetaMask - </strong><a href="https://metamask.io/download/">https://metamask.io/download/</a> <br/><br/>

        B. <strong>Funding your MetaMask account.</strong><br/>
        •<strong>Option 1 - </strong>Deposit from an exchange platform (Binance, coins.ph, coinbase, etc).<br/>
        •<strong>Option 2 - </strong>Purchase using debit card. (You can buy directly from MetaMask Extension using Transak. This is the safest option)<br/>
        Make sure to have enough ETH fund in your Metamask wallet (Mint price + gas fee).<br/>
        We recommend you to have extra ETH so that you have extra for the gas fee especially if the network is busy. We recommend you to mint when the network is NOT busy.<br/><br/>

        C. <strong>Minting RBMC NFTs.</strong><br/>
        •You can purchase your own RBMC NFT in this link: <a href="https://rbmc.io/mint">(https://rbmc.io/mint) </a><br/>
        •Max 20 NFTs per transaction<br/>
        •Minting RBMC NFT is random<br/><br/>

        D. <strong>Verifying your NFT to become Royal Bully.</strong><br/>
        •Verify your Metamask wallet holding your RBMC NFT to get your official role here:<br/>
        <a href="https://discord.com/channels/942673215803772958/942675716024205322">https://discord.com/channels/942673215803772958/942675716024205322</a><br/>
        RBMC NFTs are automatically listed in Opensea.io upon minting Purchase Our<br/>
        Royal Bully Collection in Opensea.io After Minting is Sold Out<br/>
        •You can view and purchase your own RBMC NFT after minting sold out in this link: <a href="https://opensea.io/collection/rbmc">(https://opensea.io/collection/rbmc) </a><br/>
        (IMPORTANT: Only buy RBMC NFTs created by RoyalBullyMillionairesClub)<br/><br/>

        <strong>FOR MOBILE:</strong><br/>
        •<strong>Video tutorial - </strong><br/>
        <a href="https://www.youtube.com/watch?v=7KE_QTXnoh4">https://www.youtube.com/watch?v=7KE_QTXnoh4 </a><br/>
        {/* A. <strong>Download and create a MetaMask account. (NEVER SHARE </strong>your seed phrase to anyone and keep it safe for future recovery if needed) <br/>
        •<strong>Tutorial - </strong><a href="https://youtu.be/WAStJtjYI_c">https://youtu.be/WAStJtjYI_c</a> <br/>
        •<strong>MetaMask - </strong><a href="https://metamask.io/download/">https://metamask.io/download/</a> <br/><br/>
        B. <strong>Funding your MetaMask account. (3 Options)</strong><br/>
        •<strong>Option 1 - </strong>Direct purchase from a person. Also known as P2P. (Risky) <br/>
        •<strong>Option 2 - </strong>Deposit from an exchange platform (Binance, coins.ph, etc). <br/>
        •<strong>Option 3 - </strong>Purchase using debit card. (You can buy directly from MetaMask Extension using Transak. This is the safest option) <br/>
        Make sure to have enough ETH fund in your Metamask wallet (Mint price + gas fee). <br/>
        We recommend you to have at least 20$ worth of ETH so that you have extra for the gas fee especially if the network is busy. We recommend you to mint when the network is NOT busy. <br/>
        C. <strong>Minting RBMC NFTs</strong><br/>
        •You can purchase your own RBMC NFT in this link: <a href="https://rbmc.io/mint">(https://rbmc.io/mint) </a><br/>
        Minting RBMC NFT is random<br/><br/>
        D. <strong>Verifying your NFT to become Royal Bully</strong> <br/>
        •Verify your Metamask wallet holding your RBMC NFT to get your official role here: <br/>
        <a href="https://discord.com/channels/942673215803772958/942675716024205322">https://discord.com/channels/942673215803772958/942675716024205322</a><br/>
        <strong>RBMC NFTs are automatically listed in Opensea.io upon minting Purchase Our</strong> <br/>
        <strong>Royal Bully Collection in Opensea.io After Minting is Sold Out</strong><br/>
        •You can purchase your own RBMC NFT after minting sold out in this link: (TBA)<br/>
        (<strong>IMPORTANT: </strong>Only buy RBMC NFTs created by <strong>RoyalBullyMillionairesClub</strong>) */}
        </Accordion> 
        <Accordion ScrollTrigger = {
            ScrollTrigger
        }
        title = "What are the 4 Phases and their price?" >
        4 Phases Sale: (2,500 RBMC NFT each phase)<br/>
            -Phase 1: 0.05 ETH<br/>
            -Phase 2: 0.065 ETH<br/>
            -Phase 3: 0.08 ETH<br/>
            -Phase 4: 0.10 ETH<br/>
 </Accordion> 
        </Box> 
        <Box >
        
        
 <Accordion ScrollTrigger = {
            ScrollTrigger
        }
        title = " What’s the reason behind the 4 phases price tier?" >
        We decided to do 4 phases price tier due to the following:<br/><br/>
        A. We want to lower the risk exposure of our phase 1 supporters by 
        increasing the price of each wave. This will benefit all tiers as phase 1 and 2 
        purchases will help build the foundation and gain traction for our community 
        while phase 3, 4, and secondary market purchases will then still benefit as they 
        will be entering our community with a foundation that is already built and 
        incubating royal profits for our community.<br/><br/>
        B. We wanted quality over quantity for our community. With the 4 phase 
        price tier, we will be able to target quality members that share the same mission 
        and vision that we have instead of whitelist giveaways which most of the time 
        will only target NFT flippers.<br/>
 </Accordion> 
 <Accordion ScrollTrigger = {
            ScrollTrigger
        }
        title = "How do I get support from the team or community?" >
        You may get support from our team or community by joining our discord server at <a href="https://discord.gg/dzBSk8ZhFz">https://discord.gg/dzBSk8ZhFz</a>
        and head to our private support channel. You may then 
        create a ticket to talk to our team privately or ask around the community. We highly 
        encourage our royal bullies and members to help each other grow and develop.<br/>
 </Accordion> 
 <Accordion ScrollTrigger = {
            ScrollTrigger
        }
        title = " How will the founders allocate the Royal Funds?" >
        100% of sale funds - The sales proceeds will be allocated to the growth and 
        development of the community and its benefits (Partnerships, Investments, Assets, 
        Fundings, Ventures, etc) and to expand our network to help provide continuous and 
        growing benefits to RBMC NFT holders.<br/><br/>
        {/* <strong>Royal Profit Distribution</strong><br/>
        1st Year (After Sold Out)<br/>
        • 80% - Royal Members Airdrop (End of year)<br/>
        • 20% - RBMC growth and development fund<br/><br/>
        2nd Year<br/>
        • 60% - Royal Members Airdrop (Quarterly)<br/>
        • 35% - RBMC growth and development fund<br/>
        • 5% - Founders compensation<br/><br/>
        3rd Year onwards<br/>
        • 50% - Royal Members Airdrop (Quarterly)<br/>
        • 45% - RBMC growth and development fund<br/>
        • 5% - Founders compensation<br/><br/>
        As you can see, the founders will only take a share of the profit after 2 Years. Altho the 
        airdrop percentage decreases in the 2nd and 3rd year, the royal fund increases which 
        will bring in more capital resulting to increase royal profit thus maintaining the airdrop 
        average value until eventually the royal fund, royal profit, and airdrop value will 
        continuously grow to disregard the 2 years percentage decrease for the airdrop.<br/> */}
 </Accordion> 
        </Box> 
        </Container> 
        </Section>
    )
}

export default Faq