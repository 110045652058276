import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import kingdomimage from "../assets/images/the kingdom.png";

const Main = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only Screen and (max-width: 40em) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only Screen and (max-width: 40em) {
    flex-direction: column;
  }
`;

const Rocket = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-bottom: 5rem;
  @media only Screen and (max-width: 40em) {
    width: 50vw;
    padding-bottom: 0;
  }
`;

const Img1 = styled.img`
  width: 75%;
  @media only Screen and (max-width: 40em) {
    width: 150%;
    margin-bottom: 50px;
  }
`;

const Text = styled.h4`
  font-size: calc(0.5rem + 1vw);
  line-height: 1.5;
  text-align: center;
  justify-content: center;
  color: var(--nav2);
  @media only Screen and (max-width: 40em) {
    margin-top: -4rem;
    font-size: calc(0.5rem + 3vw);
  }
`;
const Circle = styled.span`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: black;
  margin-right: 0.5rem;
  margin-top: 1rem;
`;
const AboutText = styled.div`
  width: 50%;
  position: relative;
  @media only Screen and (max-width: 40em) {
    width: 100%;
  }
`;


function Kingdom() {
  
    return (
      <section>
        <div data-aos="zoom-in-up" data-aos-duration="750" data-aos-mirror="true" class="container-xl pt-5 text-center">
              <h1 class="fw-semibold col-8 col-md-12 mx-auto display-5 mt-5 mb-4">THE KINGDOM</h1>
              </div>
              <Content>
                <Rocket>
                  <Img1 src={kingdomimage} alt="" />
                </Rocket>
              </Content>
              <div data-aos="zoom-in-up" data-aos-duration="750" data-aos-mirror="true" class="container-xl pt-5 text-center">
              <Text>
                <strong style={{fontSize: 25}}>The time to rise.<br/>
                The time to empower.</strong><br/><br/>
                The towns of the metaverse are crowded. Every kingdom homes its share of 
                exotic pets and furry companions. In scope, the royal bullies lead the way. 
                Although prestigiously stamped as bullies, these leaders do no harm. They are 
                labeled bullies because of their immense impact and potential for good. And 
                they do good for others. The Royal Bullies Millionaires Club is an exclusive 
                community of thriving thought leaders, successful entrepreneurs, and creators 
                who are transforming their crowded towns into wealthy estates where the 
                luxuries of the world are accessible and free. No monarch rules the club. The 
                wealth and power are distributed from bully to bully, shared among themselves, 
                and amplified as one.<br/><br/>
                A premium collection of 10,000 royal bullies that grants you exclusive access to 
                The Kingdom, where only royals roam freely accessing exclusive events, 
                workshops, giveaways, privileges, and benefits. The Kingdom will provide you 
                with the opportunity to broaden your network internationally and build multiple 
                empires together.<br/><br/>
                The time is now to rise. There is a royal bully in all of us, with the potential to 
                create well and empower others in the process.<br/>
               </Text>
               </div>
           </section>
        )
}

export default Kingdom