import React from "react";
import styled from "styled-components";
import preparation from "../assets/images/Economic preparation.jpg";
import stimulation from "../assets/images/Economic stimulation.jpg";
import Exploration from "../assets/images/Exploration.jpg";
import commencement from "../assets/images/Royal commencement.jpg";
import expansion from "../assets/images/Royal expansion.jpg";

const Section = styled.section `
  width: 100vw;
  background-color: ${(props) => props.theme.body};
  position: relative;
  display: inline-block;
  overflow: hidden;
`;

const Title = styled.h1 `
  font-size: 3rem;
  text-transform: capitalize;
  color: var(--nav2);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.text};
  width: fit-content;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Roadmap = () => {

    return ( 
   /*  <Section data-aos="zoom-in-up" data-aos-duration="750" data-aos-mirror="true" id = "roadmap" > */
      <Section  id = "roadmap" >
        <div data-aos="zoom-in-up" data-aos-duration="750" data-aos-mirror="true" class="container-xl pt-5 text-center">
         <h1 class="fw-semibold col-8 col-md-12 mx-auto display-5 mt-5 mb-4">ROADMAP</h1>
        </div>
        <div class="container">
        <div class="timeline">

        {/* ................................................. */}
        <div class="line text-muted"></div>

        <div class="separator text-muted"></div>

        <article class="panel panel-primary">

            <div class="panel-heading">
                <h2 class="panel-title"> <strong>Phase 1: (Royal Commencement)</strong></h2>
            </div>
    
        </article>

        <article class="panel panel-default panel-outline">

            <div class="panel-body">
                <img class="img-responsive img-rounded" src={commencement} width="35%"/>
            </div>

    
        </article>

        <article class="panel panel-primary">

            <div class="panel-heading">
                <h3 class="panel-title">Community building and engagement will begin. Promotions and collaborations will prevail in this phase. Introducing our Royal Family to the public.</h3>
            </div>
    
        </article>
        {/* ................................................. */}
        <div class="line text-muted"></div>

        <div class="separator text-muted"></div>

        <article class="panel panel-primary">

            <div class="panel-heading">
                <h2 class="panel-title"> <strong>Phase 2: (Royal Expansion)</strong></h2>
            </div>
    
        </article>

        <article class="panel panel-default panel-outline">

            <div class="panel-body">
                <img class="img-responsive img-rounded" src={expansion} width="35%"/>
            </div>

    
        </article>

        <article class="panel panel-primary">

            <div class="panel-heading">
                <h3 class="panel-title">Partnerships will be secured. Allies will be made and our community will further develop. Digital and IRL businesses and communities will join forces with our royal family providing benefits and privileges for our holders.</h3>
            </div>
    
        </article>
        {/* ................................................. */}
        <div class="line text-muted"></div>

        <div class="separator text-muted"></div>

        <article class="panel panel-primary">

            <div class="panel-heading">
                <h2 class="panel-title"> <strong>Phase 3: (Economic Preparation)</strong></h2>
            </div>
    
        </article>

        <article class="panel panel-default panel-outline">

            <div class="panel-body">
                <img class="img-responsive img-rounded" src={preparation} width="35%"/>
            </div>

    
        </article>

        <article class="panel panel-primary">

            <div class="panel-heading">
            <h3 class="panel-title">As the community successfully onboarded 5,000 Royal Bullies, economic preparation 
            will start by onboarding our team of lawyers that will aid and protect us in our legal 
            movements IRL and digital.</h3>
            </div>
    
        </article>
        {/* ................................................. */}
        {/* ................................................. */}
        <div class="line text-muted"></div>

        <div class="separator text-muted"></div>

        <article class="panel panel-primary">

            <div class="panel-heading">
                <h2 class="panel-title"> <strong>Phase 4: (Economic Stimulation)</strong></h2>
            </div>
    
        </article>

        <article class="panel panel-default panel-outline">

            <div class="panel-body">
                <img class="img-responsive img-rounded" src={stimulation} width="35%"/>
            </div>

    
        </article>

        <article class="panel panel-primary">

            <div class="panel-heading">
                <h3 class="panel-title">This is where we make carefully calculated decisions in building our empire. The 
opportunities for our Royal Bullies will be extraordinary. The Royal Family will reign and 
the benefits will continue to grow and provide for our holders.</h3>
            </div>
    
        </article>
        {/* ................................................. */}
        {/* ................................................. */}
        <div class="line text-muted"></div>

        <div class="separator text-muted"></div>

        <article class="panel panel-primary">

            <div class="panel-heading">
                <h2 class="panel-title"> <strong>Phase 5: (Royal Exploration)</strong></h2>
            </div>
    
        </article>

        <article class="panel panel-default panel-outline">

            <div class="panel-body">
                <img class="img-responsive img-rounded" src={Exploration} width="35%"/>
            </div>

    
        </article>

        <article class="panel panel-primary">

            <div class="panel-heading">
                <h3 class="panel-title">The phase where we will break walls and boundaries. The phase where we research 
and develop new benefits and privileges that we can sustainably provide to our Royal 
Bullies.</h3>
            </div>
    
        </article>
        {/* ................................................. */}

    </div>

</div>
        </Section>
    );
};

export default Roadmap;