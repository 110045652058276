import React, { useEffect, useState, useRef } from "react";
import { FiMenu, FiX } from 'react-icons/fi';
import '../components/NavBar.css';
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import discord from "../assets/images/discord.png"
import twitter from "../assets/images/twitter.png"
import opensea from "../assets/images/opensea.png"
import logo from "../assets/images/hs-logo.png"
import linktr from "../assets/images/linktr.png"
import * as s from "../styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;


function minting() {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [claimingNft, setClaimingNft] = useState(false);
    const [feedback, setFeedback] = useState(`Click Confirm to mint your NFT.`);
    const [mintAmount, setMintAmount] = useState(1);
    const [CONFIG, SET_CONFIG] = useState({
      CONTRACT_ADDRESS: "",
      SCAN_LINK: "",
      NETWORK: {
        NAME: "",
        SYMBOL: "",
        ID: 0,
      },
      NFT_NAME: "",
      SYMBOL: "",
      MAX_SUPPLY: 1,
      WEI_COST: 0,
      DISPLAY_COST: 0,
      GAS_LIMIT: 0,
      MARKETPLACE: "",
      MARKETPLACE_LINK: "",
      SHOW_BACKGROUND: false,
    });
  
    const claimNFTs = () => {
      let cost = CONFIG.WEI_COST;
      let gasLimit = CONFIG.GAS_LIMIT;
      let totalCostWei = String(cost * mintAmount);
      let totalGasLimit = String(gasLimit * mintAmount);
      console.log("Cost: ", totalCostWei);
      console.log("Gas limit: ", totalGasLimit);
      setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
      setClaimingNft(true);
      blockchain.smartContract.methods
        .mint(mintAmount)
        .send({
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: totalCostWei,
        })
        .once("error", (err) => {
          console.log(err);
          setFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(
            `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
          );
          setClaimingNft(false);
          dispatch(fetchData(blockchain.account));
        });
    };
  
    const decrementMintAmount = () => {
      let newMintAmount = mintAmount - 1;
      if (newMintAmount < 1) {
        newMintAmount = 1;
      }
      setMintAmount(newMintAmount);
    };
  
    const incrementMintAmount = () => {
      let newMintAmount = mintAmount + 1;
      if (newMintAmount > 20) {
        newMintAmount = 20;
      }
      setMintAmount(newMintAmount);
    };
  
    const getData = () => {
      if (blockchain.account !== "" && blockchain.smartContract !== null) {
        dispatch(fetchData(blockchain.account));
      }
    };
  
    const getConfig = async () => {
      const configResponse = await fetch("/config/config.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const config = await configResponse.json();
      SET_CONFIG(config);
    };
  
    useEffect(() => {
      getConfig();
    }, []);
  
    useEffect(() => {
      getData();
    }, [blockchain.account]);
    const [open, setOpen] = useState(false);

    const handleClick = () => {
      setOpen(!open);
    };
  
    const closeMenu = () => {
      setOpen(false);
    };
  
    return (
            <>
                <nav className="navbar navbar-expand-lg bg-none fixed-top">
              <a href="/" class="nav-logo">
                <img class="logo" src={logo}  width="300" height="50" draggable="false"/>
              </a>
              <span class="d-md-block d-block mx-auto">
                                          <a href="https://linktr.ee/rbmc">
                                              <img class="social-icon" src={linktr} width="41" height="41" />
                                          </a>
                                          <a href="https://discord.com/invite/dzBSk8ZhFz">
                                              <img class="social-icon" src={discord} width="41" height="41" />
                                          </a>
                                          <a href="https://twitter.com/rbmc_nft">
                                              <img class="social-icon mx-2" src={twitter} width="41" height="41" />
                                          </a>
                                          <a href="https://opensea.io/collection/rbmc">
                                              <img class="social-icon" src={opensea} width="41" height="41"/>
                                          </a>
                                          
                    </span>                                      
                    <div onClick={handleClick} className="nav-icon">
                      {open ? <FiX /> : <FiMenu />}
                    </div>
                    <ul className={open ? 'nav-links active' : 'nav-links'}>
                      <li className="nav-item">
                      <a href="#About">
                        <span class="nav-link">About us</span>
                      </a>
                      </li>
                      <li className="nav-item">
                      <a href="/#Utility">
                                <span class="nav-link">Utility</span>
                            </a>
                      </li>
                      <li className="nav-item">
                      <a href="/#roadmap">
                                <span class="nav-link">Roadmap</span>
                            </a>
                      </li>
                      <li className="nav-item">
                      <a href="/#team">
                                <span class="nav-link">Team</span>
                            </a>
                      </li>
                      <li className="nav-item">
                      <a href="/#faq">
                                <span class="nav-link">FAQs</span>
                            </a>
                      </li>
                      <a href="/">
                                <span class="ms-2 btn btn-light btn-mint">Home</span>
                    </a>
                    </ul>
                    
                  </nav>

                {/* <!-- Hero --> */}
                <section>
                    <div style={{height: "100vh"}} class="bg-hero position-relative">
                        <div style={{height: "100%"}} class="d-flex align-items-center justify-content-center">
                            <div class="pt-5 pt-md-0 mt-5">
                                <div class="row mx-4 mx-md-0 p-5 bg-white rounded-mint-border" style={{ maxWidth: "700px"}}>
                                    <h1 class="fw-semibold col-12 mx-auto display-4 mb-4 px-0 px-md-5 text-center lh-1">RBMC</h1>
                                    {/* <div class="col-12 progress px-0 mb-2 w-100" style={{ borderRadius: "200px", height: "30px"}} >
                                    <div class="progress-bar bg-rainbow" role="progressbar" style={{width: (data.totalSupply / CONFIG.MAX_SUPPLY) * 100 }} aria-valuenow= {data.totalSupply} aria-valuemin="0" aria-valuemax={CONFIG.MAX_SUPPLY}></div>
                                    </div>
                                    <h5 class="text-center mb-4 fw-semibold"><span>{data.totalSupply} / {CONFIG.MAX_SUPPLY}</span> RBMC</h5> */}
                                    {/* <form class="px-0">
                                        <div class="row">
                                            <div class="col-12 col-md-6 mb-3">
                                                <h6 class="text-center mb-2 fw-semibold">QUANTITY</h6>
                                                <div class="input-group">
                                                    <button class="btn btn-outline-mint" type="button" id="button-addon-left">-</button>
                                                    <input class="fw-semibold form-control form-control-lg text-center mint-field" min="1" type="number" id="qty" placeholder="1" readonly/>
                                                    <button class="btn btn-outline-mint" type="button" id="button-addon-right">+</button>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 mb-3">
                                                <h6 class="text-center mb-2 fw-semibold">TOTAL PRICE</h6>
                                                <div class="input-group">
                                                    <input class="fw-semibold form-control form-control-lg text-center pill  mint-field" style={{border: "3px", solid: "#000"}} min="1" type="number" id="price" placeholder="0.06 ETH" readonly/>
                                                </div>
                                            </div>
                                            <div class="col">
                                                 <button class="btn text-center pill btn-mint mt-4 mt-md-0 mb-3 w-100 confirm-mint" onClick={(e) => {
                                                    e.preventDefault();
                                                    dispatch(connect());
                                                    getData();
                                                }}
                                                >
                                                CONNECT
                                                </button> 
                                                
                                                
                                            </div>
                                        </div>
                                    </form> */}




                                    <div >
                                      {/* <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--primary-text)",
                                        }}
                                      >
                                        <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                                          {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                                        </StyledLink>
                                      </s.TextDescription> */}
                                      <s.SpacerSmall />
                                      {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                                        <>
                                          <s.TextTitle
                                            style={{ textAlign: "center", color: "var(--accent-text)" }}
                                          >
                                            The sale has ended.
                                          </s.TextTitle>
                                          <s.TextDescription
                                            style={{ textAlign: "center", color: "var(--accent-text)" }}
                                          >
                                            You can still find {CONFIG.NFT_NAME} on
                                          </s.TextDescription>
                                          <s.SpacerSmall />
                                          <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                                            {CONFIG.MARKETPLACE}
                                          </StyledLink>
                                        </>
                                      ) : (
                                        <>
                                          {/* <s.TextTitle
                                            style={{ textAlign: "center", color: "var(--accent-text)" }}
                                          >
                                            1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
                                            {CONFIG.NETWORK.SYMBOL}.
                                          </s.TextTitle>
                                          <s.SpacerXSmall />
                                          <s.TextDescription
                                            style={{ textAlign: "center", color: "var(--accent-text)" }}
                                          >
                                            Excluding gas fees.
                                          </s.TextDescription>
                                          <s.SpacerSmall /> */}
                                          {blockchain.account === "" ||
                                          blockchain.smartContract === null ? (
                                            <s.Container ai={"center"} jc={"center"}>
                                              <s.TextTitle
                                            style={{ textAlign: "center", color: "var(--accent-text)" }}
                                          >
                                            <strong> PHASE 1 </strong>
                                          </s.TextTitle>
                                          <s.SpacerSmall />
                                              <s.TextDescription
                                                style={{
                                                  textAlign: "center",
                                                  color: "var(--accent-text)",
                                                }}
                                              >
                                                Connect to the {CONFIG.NETWORK.NAME} network to see minting progress.
                                              </s.TextDescription>
                                              <s.SpacerSmall />
                                              <button class="btn text-center pill btn-mint mt-4 mt-md-0 mb-3 w-100 confirm-mint"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  dispatch(connect());
                                                  getData();
                                                }}
                                              >
                                                CONNECT
                                              </button>
                                              {blockchain.errorMsg !== "" ? (
                                                <>
                                                  <s.SpacerSmall />
                                                  <s.TextDescription
                                                    style={{
                                                      textAlign: "center",
                                                      color: "var(--accent-text)",
                                                    }}
                                                  >
                                                    {blockchain.errorMsg}
                                                  </s.TextDescription>
                                                </>
                                              ) : null}
                                            </s.Container>
                                          ) : (
                                            <>
                                            <div class="col-12 progress px-0 mb-2 w-100" style={{ borderRadius: "200px", height: "30px"}} >
                                    <div class="progress-bar bg-rainbow" role="progressbar" style={{width: (data.totalSupply / CONFIG.MAX_SUPPLY) * 100 }} aria-valuenow= {data.totalSupply} aria-valuemin="0" aria-valuemax={CONFIG.MAX_SUPPLY}></div>
                                    </div>
                                    <h5 class="text-center mb-4 fw-semibold"><span>{data.totalSupply} / {CONFIG.MAX_SUPPLY}</span> RBMC</h5>
                                              <s.TextDescription
                                                style={{
                                                  textAlign: "center",
                                                  color: "var(--accent-text)",
                                                }}
                                              >
                                                {feedback}
                                              </s.TextDescription>
                                              <s.SpacerMedium />
                                              
                                              <div class="row">
                                              <div class="col-12 col-md-6 mb-3">
                                                <h6 class="text-center mb-2 fw-semibold">QUANTITY</h6>
                                              <div class="input-group">
                                                <button class="btn btn-outline-mint" type="button" id="button-addon-left"
                                                  disabled={claimingNft ? 1 : 0}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    decrementMintAmount();
                                                  }}
                                                >
                                                  -
                                                </button>
                                                
                                                <input class="fw-semibold form-control form-control-lg text-center mint-field" id="qty" placeholder={mintAmount} readonly disabled/>
                                                {/* <s.TextDescription
                                                  style={{
                                                    textAlign: "center",
                                                    color: "var(--accent-text)",
                                                  }}
                                                >
                                                  {mintAmount}
                                                </s.TextDescription> */}
                                                
                                                <button class="btn btn-outline-mint" type="button" id="button-addon-right"
                                                  disabled={claimingNft ? 1 : 0}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    incrementMintAmount();
                                                  }}
                                                >
                                                  +
                                                </button>
                                                
                                              </div>
                                              </div>
                                              <div class="col-12 col-md-6 mb-3">
                                                <h6 class="text-center mb-2 fw-semibold">TOTAL PRICE</h6>
                                                <div class="input-group">
                                                    <input class="fw-semibold form-control form-control-lg text-center pill  mint-field" style={{border: "3px", solid: "#000"}} id="price" placeholder={mintAmount * 0.05 } readonly disabled/>
                                                </div>
                                            </div>
                                              </div>
                                              
                                              <s.SpacerSmall />
                                              <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                                <button class="btn text-center pill btn-mint mt-4 mt-md-0 mb-3 w-100 confirm-mint"
                                                  disabled={claimingNft ? 1 : 0}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    claimNFTs();
                                                    getData();
                                                  }}
                                                >
                                                  {claimingNft ? "MINTING" : "CONFIRM MINT"}
                                                </button>
                                              </s.Container>
                                              <s.TextDescription
                                                style={{
                                                  textAlign: "center",
                                                  color: "var(--accent-text)",
                                                }}
                                              >
                                                Please make sure you are connected to the right network (Etherium Mainnet) and the correct address. Please note: Once you make the purchase, you cannot undo this action.
                                              </s.TextDescription>
                                            </>
                                          )}
                                        </>
                                      )}
                                    </div>

                                    {/* ////////////////////////////////////////// */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <footer>
                    <div class="bg-dark text-white text-center">
                        <p class="p-2 mb-0">&copy; Copyright 2022, RBMC. All rights reserved.</p>
                    </div>
                </footer>

                
            </>
        )
}

export default minting